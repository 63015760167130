import React from 'react';
import cx from 'clsx';
import data from './data';
import { navigate } from 'gatsby';
import { Button } from '@/components/inputs';
import * as styles from './NotFound404Section.module.scss';

const NotFound404Section = () => {
  return (
    <section className={styles.section}>
      <div className="container text-center">
        <div className={styles.sectionContent}>
          <h1
            className={cx('c-section-title', styles.title)}
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <p className="mt-4">
            <Button
              theme="primary"
              onClick={() => {
                navigate('/');
              }}
              role="link"
            >
              {data.goToHomeText}
            </Button>
          </p>
        </div>
      </div>
    </section>
  );
};

export default NotFound404Section;
