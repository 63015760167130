import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';

import { DrawerProvider } from '@/contexts/DrawerContext';
import Seo from '@/components/seo';
import TopNavbar from '@/containers/HomePage/TopNavbar/TopNavbar';
import NotFound404Section from '@/containers/404/NotFound404Section/NotFound404Section';
import Modals from '@/containers/HomePage/Modals/Modals';

import {
  topNavbarStickyContainer,
  isSticky,
  innerWrapper,
} from '@/containers/HomePage/TopNavbar/TopNavbar.module.scss';

const NotFound = () => {
  return (
    <Fragment>
      <Seo title="Страница не найдена" />
      <Sticky
        top={0}
        innerZ={998}
        className={topNavbarStickyContainer}
        activeClass={isSticky}
        innerClass={innerWrapper}
      >
        {() => (
          <DrawerProvider>
            <TopNavbar isSticky={true} hasMenu={false} />
          </DrawerProvider>
        )}
      </Sticky>
      <NotFound404Section />

      <Modals />
    </Fragment>
  );
};

export default NotFound;
